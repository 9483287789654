import PropTypes from 'prop-types'

import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { handleTag } from '@saatva-bits/pattern-library.modules.detail-modal'

import { getDetailTileArdadImages } from '@/utils/product'

import productCodes from '@/temp-configs/product-codes'

const productsSort = [
    productCodes.classic,
    productCodes.saatvaRX,
    productCodes.latexHybrid,
    productCodes.contour5,
    productCodes.memoryFoamHybrid,
    productCodes.zenhaven
]

const ProductTile = ({ product }) => {
    const { getDiscounts } = useGetDiscounts()
    const productState = useProductState(product.productCode, ['assets', 'genericName', 'sku'])

    const { finalPrice: discountedPrice } = getDiscounts([{
        sku: product.productCode,
        parentSku: product.productCode,
        price: product.lowestPrice,
        category: product.category.toLowerCase(),
        quantity: 1,
        isPrimaryProduct: true
    }])
    const { defaultImage, hoverImage } = getDetailTileArdadImages(product.ardadDescriptors, productState)
    const tag = handleTag(product.isNew, product.tag)

    return (
        <div key={product.productCode} className="col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl">
            <DetailProductTile
                title={{ text: product.title, href: product.url }}
                subtitle={{ text: product.subtitle }}
                priceRange={{
                    finalPriceMin: product.lowestPrice,
                    finalPriceMax: product.highestPrice
                }}
                imageHref={product.url}
                affirm={{ price: discountedPrice }}
                defaultImage={defaultImage}
                hoverImage={hoverImage}
                imageBadgeLeft={tag && { text: tag }}
            />
        </div>
    )
}

const ProductTileSet = ({ products }) => {
    const { isMobile } = useBreakpoints('desktop')

    if (!products) {
        return null
    }

    // TODO: once test 'EXP.HP_MATTRESS_TILES.DIS-271' is complete, move this sort to the server side
    const sortedProducts = products
        .filter(({ productCode }) => productsSort.includes(productCode))
        .sort((productA, productB) => productsSort.indexOf(productA.productCode) - productsSort.indexOf(productB.productCode))

    const productList = isMobile ? sortedProducts.slice(0, 3) : sortedProducts

    return (
        <div className="row u-flexJustify--center">
            {productList.map(product => (
                <ProductTile key={product.productCode} product={product} />
            ))}
        </div>
    )
}

ProductTileSet.propTypes = {
    products: PropTypes.array
}

export default ProductTileSet
