import { SelectionProvider } from '@saatva-bits/pattern-library.modules.selection'

import Layout from '@/layouts/Default'
import HeroSection from '@/components/HeroSection/HeroSection'
import ValuePropsSection from '@/components/ValuePropsSection/ValuePropsSection'
import ProductTiles from '@/components/ProductTiles/ProductTiles'
import VideoSection from '@/components/VideoSection/VideoSection'
import CategoryTiles from '@/components/CategoryTiles/CategoryTiles'
import WhySaatva from '@/components/WhySaatva/WhySaatva'
import ReviewSection from '@/components/ReviewSection/ReviewSection'

export default function HomeView(props) {
    const products = props.products
    return (
        <Layout {...props}>
            <SelectionProvider productsData={products} defaultInStock={true}>
                <HeroSection {...props} />
                <ValuePropsSection />
                <ProductTiles products={products} />
                <WhySaatva />
                <ReviewSection reviews={props.reviewList} />
                <VideoSection />
                <CategoryTiles />
            </SelectionProvider>
        </Layout>
    )
}
