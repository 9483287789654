import omit from 'lodash/omit'
import { getColorNameForImages } from '@saatva-bits/pattern-library.utils.product'
import { getAssetForVariant } from '@saatva-bits/pattern-library.modules.selection'
import logger from '@saatva-bits/pattern-library.utils.logger'

import { getVariantAttributeValues } from './filters'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

/**
 * Adds sortKeys and adds product and compare URIs
 *
 * @param {object} product
 * @returns
 */
export const formatProductDetails = (product) => {
    let details = {}
    product.details && product.details.forEach((detail, index) => {
        const key = detail.key
        const name = detail.name
        const features = omit(detail, ['key', 'name'])

        // This key (feature, firmness) has been added so we push into content array!
        if (details[key] && details[key].content && details[key].content.length > 0) {
            details[key].content.push(features)
        } else {
            details = {
                ...details,
                [key]: {
                    sortOrder: index,
                    key,
                    name,
                    content: [
                        features
                    ]
                }
            }
        }
    })
    const detailsList = Object.values(details).sort((a, b) => a.sortOrder - b.sortOrder)

    const shopCta = product.hasShopCta ? `/${product.category}/${product.sku}` : null
    const compareCta = product.hasCompareCta ? `/${product.category}/compare?sku=${product.sku}` : null

    return {
        details: detailsList,
        shopCta,
        compareCta
    }
}

/**
 * Build an object from an array of attributes for easy data access
 * @param {Object[]} attributes
 * @param {String} attributes.name
 * @param {String} attributes.value
 */
const buildAttributeObject = (attributes) => {
    if (!attributes) {
        return {}
    }

    const contentAttributeValues = {}

    attributes.forEach(attribute => {
        contentAttributeValues[attribute]

        if (contentAttributeValues[attribute.name]) {
            contentAttributeValues[attribute.name].push(attribute.value)
        } else {
            contentAttributeValues[attribute.name] = [attribute.value]
        }
    })

    return contentAttributeValues
}

/**
 * This is done to make variant filtering work, we add parent level attributes to each variant
 * to not change the implementation
 * @param {Object[]} variants
 * @param {Object} content
 * @param {Object[]} filterConfig
 */
const enrichVariantsWithFilterData = (variants, content, filterConfig) => {
    const contentAttributeValues = buildAttributeObject(content.attributes)

    for (let variant of variants) {
        for (const config of filterConfig) {
            // Only enrich parent filters
            if (config.target === 'parent') {
                for (const attributePath of config.attributePaths) {
                    // only enrich if its not overriding property from plytix
                    if (!variant[attributePath]) {
                        // first check the new attributes array froming from prismic
                        // then use the legacy attribute definition
                        if (contentAttributeValues[attributePath]) {
                            variant[attributePath] = contentAttributeValues[attributePath]
                        } else if (content[attributePath]) {
                            variant[attributePath] = content[attributePath].map(valueObj => valueObj.value)
                        }
                    }
                }
            }
        }
    }
    return variants
}

/**
 * Merges prismic product content with catalog product data
 *
 * @param {object} contentProduct
 * @param {object} catalogProduct
 * @param {object[]?} filterConfig
 * @returns
*/
export const formatProduct = (contentProduct, catalogProduct, filterConfig = []) => {
    return {
        ...contentProduct,
        ...catalogProduct,
        ...catalogProduct.content,
        ...formatProductDetails(contentProduct),
        variants: enrichVariantsWithFilterData(catalogProduct.variants, contentProduct, filterConfig)
    }
}

/**
 * Format the swatch name. Fixes an error where getting the color twice returns an invalid color.
 *
 * @param {string} swatch
 * @returns {string}
*/
export const formatSwatch = swatch => {
    let formattedSwatch = swatch.toLowerCase()
    switch (formattedSwatch) {
        case 'natural oak':
            formattedSwatch = 'natural oak'
            break
        default:
            formattedSwatch = getColorNameForImages(formattedSwatch)
            break
    }

    return formattedSwatch
}

/**
 * @param {{
 *      attributePaths: string[];
 *      label: string;
 *      property: string;
 *      options: {
 *          value: string;
 *          label: string;
 *          matchingValues: string[]
 *      }[]
 *      }[]
 * } colorMaterialOptions - Filter config for colors that comes from catalog, we need the matching values array for the specific color we wanna map
 * @param {Record<'selectedColors' | 'selectedMaterials', Array<{ key: string, value: string, label: string }>>} filterValuesGroup - Grouping of selections by property
 * @param {object[]} matchingVariants - Variants that matched the filter selections
 * @returns {string}
 */
export const getSwatchFromSelections = (colorMaterialOptions, filterValuesGroup, matchingVariants) => {
    if (!matchingVariants) return null

    const { selectedColors, selectedMaterials } = filterValuesGroup

    let narrowedVariants = matchingVariants

    const narrowByFilter = (variants, matchingValues, filter) => {
        return variants.filter((variant) => {
            const attributeValues = getVariantAttributeValues(variant, filter)
            return attributeValues.some((value) => {
                return matchingValues.map(value => value.toLowerCase()).includes(value.toLowerCase())
            })
        })
    }

    if (selectedColors?.length) {
        const values = selectedColors.map(color => color.value)
        const matchingColorValues = []

        const colorFilter = colorMaterialOptions.find(
            option => option.property === 'colors'
        )

        // We iterate each selection, this one persists the order of the selections made by the user
        // this way we can ensure the next step where we filter the variants the order is correct.
        for (const value of values) {
            const matchingFilterOption = colorFilter.options.find((option) => option.value === value)
            if (matchingFilterOption) {
                matchingColorValues.push([...matchingFilterOption.matchingValues])
            }
        }

        // if we have more than one selection, means we should try to use the first selection always
        // but if that selection doesnt match any then we use the next one and so on to persist the current behavior
        for (const matchingColors of matchingColorValues) {
            let result = narrowByFilter(narrowedVariants, matchingColors, colorFilter)

            if (result.length) {
                narrowedVariants = result
                break
            }
        }
    }

    if (selectedMaterials?.length) {
        const values = selectedMaterials.map(material => material.value)
        const matchingMaterialValues = []

        const materialFilter = colorMaterialOptions.find(
            (option) => option.property === 'materials'
        )

        for (const value of values) {
            const matchingFilterOption = materialFilter.options.find((option) => option.value === value)
            if (matchingFilterOption) {
                matchingMaterialValues.push([...matchingFilterOption.matchingValues])
            }
        }

        for (const matchingMaterials of matchingMaterialValues) {
            let result = narrowByFilter(narrowedVariants, matchingMaterials, materialFilter)

            if (result.length) {
                narrowedVariants = result
                break
            }
        }
    }

    const swatch = narrowedVariants[0]?.fabric || narrowedVariants[0]?.color

    if (!swatch) return null

    // This fixes the initial loading case where returning 'natural' would result in a conversion to 'linen' by the DetailProductTile
    return swatch
}

/**
 * Get the product swatches
 *
 * @param {array} options - Swatches values
 * @param {string} currentSwatch - Current selected swatch
 * @param {function} onChange - Triggered when a swatch is changed
 * @param {string} productCode - Product Code
 * @returns {{
 *  useInverseCheckColor: boolean,
 *  size: 'small' | 'medium',
 *  initialSelection: string,
 *  productCode: string,
 *  optionsList: { value: string, label: string, background: string, attributeCode: string }[],
 *  onChange: function
 * }}
*/
export const getSwatches = (options, currentSwatch, onChange, productCode) => {
    if (!options) {
        return null
    }

    return {
        useInverseCheckColor: true,
        size: 'small',
        initialSelection: currentSwatch,
        productCode,
        optionsList: options.values.map(value => {
            return {
                attributeCode: value.code,
                background: value.swatch,
                label: value.label,
                value: value.code
            }
        }) || [],
        onChange
    }
}

export const getArdadAssetFromVariant = (variant, descriptor, ratio) => {
    let asset = { folder: null, file: null, alt: null }
    try {
        asset = getAssetForVariant(variant, descriptor, ratio)
    } catch (error) {
        logger.error({
            message: 'Cannot find ARDAD asset for variant',
            location: 'utils/product.js',
            error,
            details: {
                productCode: variant.productCode,
                sku: variant.sku,
                descriptor
            },
            enableClientAlerts: true,
        })
    }

    return asset
}

/**
 * Get the ARDAD images for the product tiles
 *
 * @param {array} ardadDescriptors - ARDAD descriptors set in Prismic
 * @param {object} selectedVariant - The product variant where the assets are obtained
 * @param {string} ratio - Ratio for the image
 * @param {object} imageProps - Props for the Imgix Component
 * @returns {{
 *   defaultImage: { folder: string, name: string, alt: string, prefixOverride: { mobile: string, tablet: string, desktop: string }, imgixDomain: string, widths: { mobile: number }, lazyLoad: boolean, lazyLoadOffset: number },
 *   hoverImage: { folder: string, name: string, alt: string, prefixOverride: { mobile: string, tablet: string, desktop: string }, imgixDomain: string, widths: { mobile: number }, lazyLoad: boolean, lazyLoadOffset: number }
 * }}
*/
export const getDetailTileArdadImages = (
    ardadDescriptors,
    selectedVariant,
    ratio = '3-2',
    imageProps = {
        widths: { mobile: 348 },
        lazyLoad: true,
        lazyLoadOffset: null // Override the lazyload offset added by the detail product tile from bit
    }
) => {
    const [ardadDefaultImage, ardadHoverImage] = ['product-tile', 'product-tile-hover'].map(slot => {
        const descriptor = ardadDescriptors.find(descriptorDef => descriptorDef.slot == slot).descriptors
        const { folder, file } = getArdadAssetFromVariant(selectedVariant, descriptor[0], ratio)
        return {
            ...imageProps,
            folder,
            name: file,
            alt: selectedVariant.genericName,
            prefixOverride: {
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            },
            imgixDomain: IMGIX_URL
        }
    })

    return {
        defaultImage: ardadDefaultImage,
        hoverImage: ardadHoverImage
    }
}
